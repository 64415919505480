export enum BuildingIds {
  MainHouse,
  TinyHouse,
  SmallHouse,
  MediumHouse,
  BigHouse,
  HugeHouse,
  SmallStorageHouse,
  MediumStorageHouse,
  BigStorageHouse
}